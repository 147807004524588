import { tv } from "tailwind-variants";

export const useHeight = tv(
  {
    variants: {
      height: {
        navigation: "h-[--navigation-height]",
        navigationAnnouncement: "h-[--navigation-announcement-height]",
        full: "h-full",
        formControlSmall: "h-8",
        formControlDefault: "h-10",
        formControlLarge: "h-12",
        0: "h-0",
        1: "h-1",
        2: "h-2",
        3: "h-3",
        4: "h-4",
        5: "h-5",
        6: "h-6",
        7: "h-7",
        8: "h-8",
        9: "h-9",
        10: "h-10",
        11: "h-11",
      },
    },
  },
  { responsiveVariants: true }
);
