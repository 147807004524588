import { tv } from "tailwind-variants";

export const useBorderColor = tv(
  {
    variants: {
      borderColor: {
        strong: "border-black",
      },
    },
  },
  { responsiveVariants: true }
);
