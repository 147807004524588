import { tv } from "tailwind-variants";

export const useZIndex = tv(
  {
    variants: {
      zIndex: {
        navigation: "z-10",
        mobileDrawer: "z-[5]",
      },
    },
  },
  { responsiveVariants: true }
);
