import { VariantProps, tv } from "tailwind-variants";

export const useTextColor = tv(
  {
    variants: {
      color: {
        white: "text-white",
        weakest: "text-neutral-300 dark:text-neutral-500",
        weak: "opacity-50",
        strong: "text-neutral-900 dark:text-neutral-50",
      },
      hoverColor: {
        weak: "hover:text-neutral-500",
        strong: "hover:text-neutral-900 dark:hover:text-white",
      },
    },
  },
  { responsiveVariants: true }
);

export type TextColors = VariantProps<typeof useTextColor>;
