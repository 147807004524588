import { VariantProps, tv } from 'tailwind-variants';

export const useTextSizes = tv(
  {
    variants: {
      textSize: {
        xs: 'text-xs',
        sm: 'text-sm',
        base: 'text-base',
        lg: 'text-lg',
        xl: 'text-xl',
      },
    },
  },
  { responsiveVariants: true }
);

export type TextSizes = VariantProps<typeof useTextSizes>;
