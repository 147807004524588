import { tv } from "tailwind-variants";

export const useBorderRadius = tv(
  {
    variants: {
      borderRadius: {
        sm: "rounded-sm",
        md: "rounded-md",
        lg: "rounded-lg",
      },
    },
  },
  { responsiveVariants: true }
);
