import { tv } from "tailwind-variants";

export const useGridGap = tv(
  {
    variants: {
      gap: {
        2: "gap-2",
        4: "gap-4",
      },
    },
  },
  { responsiveVariants: true }
);
