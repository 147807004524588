import { tv } from "tailwind-variants";

export const useBackgroundColor = tv(
  {
    variants: {
      backgroundColor: {
        primary: "bg-white",
        contrastWeak: "bg-gray-300",
      },
    },
  },
  { responsiveVariants: true }
);
